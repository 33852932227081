import request from '@/utils/request';

export async function getUserInfo(params = {}) {
  return await request({
    url: '/sso/account/getAccount',
    responseType: 'AccountRes',
    ...params,
  });
}

// seller institution info
export async function getSellerInstitutionInfo(params = {}) {
  const req = request.create('GetInstitutionInfoReq', params);
  return await request({
    url: '/institution/institutionManage/getInstitutionInfo',
    requestBody: req,
    responseType: 'GetInstitutionInfoResp',
  });
}

// buyer institution info
export async function getBuyerInstitutionInfo(params = {}) {
  const req = request.create('GetInstitutionGeneralInfoReq', params);
  return await request({
    url: '/institution/institutionConfig/getGeneralInfo',
    requestBody: req,
    responseType: 'GetInstitutionGeneralInfoResp',
  });
}

// institutionUserInfo
export async function getInstitutionUserInfo() {
  const req = request.create('GetInstitutionUserInfoReq', {
    userAccountId: request.getAccoundId(),
  });
  return await request({
    url: '/institution/institutionManage/getInstitutionUserInfo',
    requestBody: req,
    responseType: 'GetInstitutionUserInfoResp',
  });
}

// institution business config
export function getInstitutionBusinessConfig() {
  return request({
    url: '/institution/institutionManage/getBusinessConfig',
    responseType: 'BusinessConfig',
  });
}

// find password
export async function sendEmailVerifyCode(params = {}) {
  const req = request.create('SendEamilVerifyCodeReq', params);
  return await request({
    url: '/sso/email/sendEmailVerifyCode',
    requestBody: req,
  });
}

// check code {email, verifyCode}
export async function checkRetrievePasswordVerify(params = {}) {
  const req = request.create('CheckRetrievePasswordVerifyReq', params);
  return await request({
    url: '/sso/account/checkRetrievePasswordVerify',
    requestBody: req,
    responseType: 'CheckRetrievePasswordVerifyRes',
  });
}

// reset password {accountId, verifyCode, password, confirmPassword}
export async function retrievePasswordVerify(params = {}) {
  const req = request.create('RetrievePasswordVerifyReq', params);
  return await request({
    url: '/sso/account/retrievePassword',
    requestBody: req,
  });
}

// change password {oldPassword, newPassword, confirmPassword}
export async function changePassword(params = {}) {
  const req = request.create('ChangePasswordReq', params);
  return await request({
    url: '/sso/account/changePassword',
    requestBody: req,
    showError: false,
  });
}

// 查询个人用户(accountId为3开头的)激活、认证状态
// 非个人用户调此接口，回返回错误
export async function getUserStatus() {
  return await request({
    url: '/individual/app/status',
    responseType: 'IndividualStatusResponse',
  });
}

// individual account interest
export async function tradeInterest() {
  return await request({
    url: '/individual/app/interesting',
  });
}

// 获取外部用户详细信息
export async function getIndividualUserInfo(params = {}) {
  const req = request.create('IndividualUserInfoRequest', params);
  return await request({
    url: '/individual/web/userInfo',
    requestBody: req,
    responseType: 'IndividualUserInfoRes',
  });
}
// 个人用户 - 选择询价产品
export async function favoriteQuotationProduct(params = {}) {
  const req = request.create('FavoriteQuotationProductReq', params);
  return await request({
    url: '/individual/app/favoriteQuotationProduct',
    requestBody: req,
  });
}

// activity list
export async function getActivityList(params = {}) {
  const req = request.create('ActivityListRequest', params);
  return await request({
    url: '/activity/listActivity',
    requestBody: req,
    responseType: 'ActivityListResponse',
  });
}

// create invite code
export async function createInviteCode(params = {}) {
  const req = request.create('CreateInvitationCodeRequest', params);
  return await request({
    url: '/activity/invitationCode/create',
    requestBody: req,
    responseType: 'CreateInvitationCodeResponse',
  });
}

// referral list
export async function getRefferalList(params = {}) {
  const req = request.create('ReferralListRequest', params);
  return await request({
    url: '/activity/referral/list',
    requestBody: req,
    responseType: 'ReferralListResponse',
  });
}

// get my referrals
export async function getMyReferrals() {
  return await request({
    url: '/activity/referral/my',
    responseType: 'MyReferralResponse',
  });
}

// common report
export async function commonReport(params = {}) {
  const req = request.create('CommonReportRequest', params);
  return await request({
    url: '/report/common',
    requestBody: req,
  });
}

export async function track(params = {}) {
  const url = params?.userName ? '/report/login' : '/report/unLogin';
  const req = request.create('ReportMessage', params);
  return await request({
    url,
    requestBody: req,
    responseType: 'ReportResult',
  });
}

// 配置查询
export async function queryConfig() {
  return await request({
    url: '/report/webConfig/query',
    responseType: 'ResponseConfigDetail',
    showError: false,
  });
}

// 首页live tab-on
export function listAppLiveTap() {
  return request({
    url: '/institution/institutionMonthlyStatement/listAppLiveTab',
    responseType: 'MonthlyStatements',
  });
}

// 列表页 live tap-on
export function getListClient(params = {}) {
  const req = request.create('ListClientMonthlyStatementsReq', params);
  return request({
    url: '/institution/institutionMonthlyStatement/listClientMonthlyStatements',
    requestBody: req,
    responseType: 'MonthlyStatements',
  });
}

// 文件已读上报
export function setListClientRead(params = {}) {
  const req = request.create('MonthlyStatementReadReq', params);
  return request({
    url: '/institution/institutionMonthlyStatement/monthlyStatementRead',
    requestBody: req,
  });
}
